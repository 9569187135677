import liveswitch from "fm.liveswitch";
import ChannelConnectionEvents from "./ChannelConnectionEvents";

export default class Messager {
    private channel: liveswitch.Channel;
    private events: ChannelConnectionEvents;
    private errorLogger: (err: string) => void;

    constructor(channel: liveswitch.Channel, events: ChannelConnectionEvents, errorLogger: (err: string) => void) {
        this.channel = channel;
        this.events = events;
        this.errorLogger = errorLogger;

        this.channel.addOnMessage(this.onMessage.bind(this));
    }

    public sendChatMessage(message: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.channel.sendMessage(message).then(() => {
                console.log("Sent message: " + message);
                resolve();
            }).fail(ex => {
                this.errorLogger(ex.message);
                reject();
            });
        });
    }

    private onMessage(clientInfo: liveswitch.ClientInfo, message: string) {
        this.events.notify("onmessage", { user: clientInfo.getUserAlias(), message });
    }

    public requestScreenshot(holoLensId: string): Promise<VideoFrameData> {        
        console.log(`User ${this.channel.getUserId()} requested a screenshot from ${holoLensId}`);
        this.channel.sendUserMessage(holoLensId, "screenshot");

        return new Promise((resolve, reject) => {
            this.channel.addOnUserMessage((_, data: string) => {
                const videoFrameData = JSON.parse(data);
                resolve(videoFrameData);
            });
    
            setTimeout(() => {
                reject("Screenshot request timeout");
            }, 10000);
        });
    }

    public sendImage(holoLensId: string, imageAnnotationData: ImageAnnotationData): liveswitch.Future<Object> {
        return this.channel.sendUserMessage(holoLensId, JSON.stringify(imageAnnotationData));
    }
}