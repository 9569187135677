import liveswitch from "fm.liveswitch";
import ChannelConnectionEvents from "./ChannelConnectionEvents";

export default class Screenshare {
    private localScreenMedia!: liveswitch.LocalMedia;
    private screenshareUpstreamConnection!: liveswitch.SfuUpstreamConnection;
    private events: ChannelConnectionEvents;
    private channel: liveswitch.Channel;
    private screenshareUserId: string | undefined;
    private errorLogger: (err: string) => void;

    constructor(channel: liveswitch.Channel, events: ChannelConnectionEvents, errorLogger: (err: string) => void) {
        this.channel = channel;
        this.events = events;
        this.errorLogger = errorLogger;
    
        // Get the screen share events
        channel.addOnRemoteUpstreamConnectionOpen(this.onRemoteUpstreamConnectionOpen.bind(this));
        channel.addOnRemoteUpstreamConnectionClose(this.onRemoteUpstreamConnectionClose.bind(this));
    }

    public toggleScreenshare(): void {
        if (this.localScreenMedia === undefined) {
            const width = 1280;
            const height = 720;
            const fps = 20;

            const videoConfig = new liveswitch.VideoConfig(width, height, fps);
            this.localScreenMedia = new liveswitch.LocalMedia(false, videoConfig, true);
        }

        if (this.localScreenMedia.getState() === liveswitch.LocalMediaState.New || this.localScreenMedia.getState() === liveswitch.LocalMediaState.Stopped) {
            this.startScreenshare();
        } else {
            this.stopScreenshare();
        }
    }

    private startScreenshare(): void {
        this.localScreenMedia.start().then(_ => {
            this.events.notify("onscreenshareupdate", { userId: this.channel.getUserId(), on: true });

            this.localScreenMedia.addOnVideoStopped(() => {
                this.stopScreenshare();
            });

            this.openScreenshareUpstreamConnection(this.localScreenMedia);
            this.screenshareUserId = this.channel.getUserId();
        }).fail(ex => this.errorLogger(ex.message));
    }

    private stopScreenshare(): void {
        this.screenshareUpstreamConnection.close().then(_ => {
            this.localScreenMedia.stop();

            this.events.notify("onscreenshareupdate", { userId: this.channel.getUserId(), on: false });
            this.screenshareUserId = undefined;
        }).fail(ex => this.errorLogger(ex.message));
    }

    public close(): void {
        if (this.screenshareUserId) {
            this.stopScreenshare();
        }
    }

    public hasUserScreensharing(): boolean {
        return this.channel !== undefined && this.channel.getRemoteClientInfos().find(x => x.getRoles()?.includes("screen")) !== undefined;
    }
    
    private openScreenshareUpstreamConnection(localMedia: liveswitch.LocalMedia) {
        const audioStream = new liveswitch.AudioStream(localMedia);
        const videoStream = new liveswitch.VideoStream(localMedia);

        this.screenshareUpstreamConnection = this.channel.createSfuUpstreamConnection(audioStream, videoStream);

        this.screenshareUpstreamConnection.addOnStateChange(conn => {
            console.log(`Upstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`);

            if (conn.getState() === liveswitch.ConnectionState.Closing || conn.getState() === liveswitch.ConnectionState.Failing) {
                if (conn.getRemoteClosed()) {
                    console.log(`Upstream connection ${conn.getId()} was closed`);
                }
            } else if (conn.getState() === liveswitch.ConnectionState.Failed) {
                this.openScreenshareUpstreamConnection(localMedia);
            }
        });

        this.screenshareUpstreamConnection.open();
    }

    private onRemoteUpstreamConnectionOpen(connectionInfo: liveswitch.ConnectionInfo) {
        if (connectionInfo.getType() === "sfu" && connectionInfo.getVideoStream().getSendContent() === "screen") {
            this.events.notify("onscreenshareupdate", { userId: connectionInfo.getUserId(), on: true });
        }
    }
    
    private onRemoteUpstreamConnectionClose(connectionInfo: liveswitch.ConnectionInfo) {
        if (connectionInfo.getType() === "sfu" && connectionInfo.getVideoStream().getSendContent() === "screen") {
            this.events.notify("onscreenshareupdate", { userId: connectionInfo.getUserId(), on: false });
        }
    }
}