import { Box, Button, Heading } from 'react-bulma-components'
import { faClock, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useJoinedChannels from '../../js/utils/useJoinedChannels';
import styles from '../../styles/lastjoinedchannels.module.css';
import styleBox from '../../styles/box.module.css';
import { useState } from 'react';

type Props = {
    onJoinChannelClick: (id: string) => void
}

export default function LastJoinedChannels({ onJoinChannelClick }: Props) {
    const [,forceUpdate] = useState({});
    const [channels] = useJoinedChannels();

    function onClearClicked() {
        localStorage.removeItem("channels");
        channels.clear();
        forceUpdate({});
    }

    return (
        <Box className={styleBox.box}>
            <Heading className={styles.heading} size={4}>
                Channels
                <Button disabled={channels.size <= 0} className={styles.clearBtn} rounded onClick={onClearClicked}>Clear</Button>
            </Heading>
            <Heading subtitle size={6}>Here are the last channels you joined recently</Heading>
            <ul>
            {
                Array.from(channels.entries()).sort((a, b) => a[1] >= b[1] ? -1 : 1).map(([channel, timestamp]) => {
                    return (
                        <li className={styles.item} key={channel} onClick={() => onJoinChannelClick(channel)}>
                            <div>
                                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faTv} />
                                {channel}
                            </div>
                            <p style={{ fontSize: '0.8em', opacity: 0.8, paddingTop: 5 }}>
                                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faClock} />
                                {new Date(timestamp).toLocaleString()}
                            </p>
                        </li>
                    );
                })
            }
            </ul>
        </Box>
    );
}